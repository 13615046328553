* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  justify-content: space-between;
  /* cursor: col-resize; */
  
}

/* body{
  padding: 0 120px;
} */

/* ////////////header/////////////////////// */

.home {
  background:linear-gradient(rgba(7, 25, 104, 0.3),rgba(238, 97, 196, 0.9));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(px);
  width: 100%;
  /* height: 100%; */
}
.header {
  display: flex;
  /* float: right; */
  justify-content: space-between;
  text-decoration: none;
  padding: 20px 120px  ;
  margin-right: 30px;
  align-items: center;
  text-align: center;
  /* position: absolute; */
}

.brand {
  text-decoration: none;
  color:#fff;
  font-size: 49px ;
  font-weight: 700;
  letter-spacing: -normal;
  /* font-family: inter; */
  padding: 0 20px 0 0 ;
  display: flex;
  align-items: center;
}
.header_navmenu {
  /* float: right; */
  text-decoration: none;
  padding: 10px ;
  margin: 20px;
  color:#fff;
  font-size: 24px ;
  font-weight: 600;
  font-family: 'poppins',sans-serif;
  transition: all 0.3s ease;
  
}

/* 
.header_navmenu::after{
  content: '';
  background-color: blueviolet;
  height: 3px;
  width: 0;
  /* left: 0; */
  /* bottom: -10px; */
  /* transition: all 0.3s ease; */
  /* margin: auto; */



.header_navmenu:hover{
  color: #fff;
  background-color: blueviolet;
  /* background-color: #ac0af7; */
  border-radius: 10px;
  border: none;
  
  
}

.header_navmenu::-webkit-scrollbar-track-piece{
  
  color: #fff;
  background-color: blueviolet;
  /* background-color: #ac0af7; */
  border-radius: 10px;
  border: none;
  
}
/* 
.header_navmenu:hover::after{
  width: 100%;
  transition: width 0.3s ease ;
} */

/* 
.header_login {
  text-decoration: none;
  color: azure;
  font-size: 24px;
  font-weight: 600;
  /* font-family: inter; */
  /* align-items: center;
  padding-right: 20px;
  background-color: blueviolet;
  padding: 3px 8px;
  border: none;
  border-radius: 8px;
  letter-spacing: -1px;
  transition: all 0.5s ease;
} */ 

/* .header_login:hover{
  background-color: blue;
} */

/* /////////////////home////////////////////// */

.home_detail{
  display: flex;
  /* background-image: url('../src/image/image/hero-bg.jpg'); */
}
.home_content{
  color: #fff;
  padding-left: 120px ;
  padding-top: 200px;
  font-size:35px;
  line-height: 100px;

}

.home_content u{
  text-decoration: underline #ac0af7;
  cursor: pointer;

}

.home_content h3{
 font-size: 30px;

}


/* .home_content u:hover{
  text-decoration: underline #0e0af7;

} */


.home_hero{
  padding: 0 120px 0 0;
}

.app{
  display: flex;
  padding: 0px;
  
 align-items: center;
 text-align: center;
 place-items: center;
 justify-content:space-between;
;  
}
.app.nav_store{
 /* margin-:20px; */
 align-items: center;
 text-align: center;
 place-items: center;
 /* justify-content: center; */
;
}
 .nav{
  
float: left;
}
 .download_btn{
  padding:18px ;
  background-color: blueviolet;
  color: #fff;
  border: none;
  font-size: 23px;
  font-weight: 600;
  margin: 0 190px 20px 0   ;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 10px;
  text-decoration: none;
  /* margin-bottom: 10px; */


}
.download_btn:hover{
  background-color: blue;
} 
.nav{
  margin: 0 10px 37px  10px;
}
 img{
border-radius: 15px;
}




/* ///////////////feature/////////////////// */


.feature{
  padding: 120px;
  display: flex;

}
.feature_img{
  /* padding: 30px;
  margin: 20px; */
  border: 1px solid black;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.5s ease;
}


.feature_img:hover{
  transform: scale(1.1);
  z-index: 1;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
